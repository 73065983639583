<template>
    <div style="background-color: #fff">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields">
                <el-button type="primary" v-access="{url:baseUrl,method:'POST'}" @click="addMessage" size="medium" slot="add" icon="el-icon-plus">新建群发信息</el-button>
            </search>
        </div>
        <div class="mt-3">
            <el-table
                    :data="tableData"
                    stripe
                    border
                    :default-sort="page.sort"
                    @sort-change="sortChange"
                    style="width: 100%">
                <el-table-column v-for="item in showFields"
                                 :prop="item.name"
                                 :key="item.name"
                                 :label="item.title"
                                 :sortable="item.sortable"
                                 :width="item.width">
                    <template slot-scope="scope">
                        <el-tag v-if="item.name==='active'" :type="scope.row.active===1?'success':'warning'">
                            {{scope.row.active===1?'正常':'禁用'}}
                        </el-tag>
                        <span v-else>{{scope.row[item.name]}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="操作"
                >
                    <template slot-scope="scope">
                        <span  v-if="scope.row.status!=10">
                        <el-button  @click="editMessage(scope.row,scope.$index)" size="mini" v-access="{method:'put',url:baseUrl}" icon="el-icon-edit"></el-button>
                        <el-button  icon="el-icon-s-promotion" v-access="{method: 'PUT',url:baseUrl}" @click="send(scope.row.message_id,scope.$index)" size="mini"></el-button>
                        </span>
                        <span v-else>
                            <el-tag type="success">发送完成</el-tag>
                        </span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pb-3 mt-3">
            <el-pagination
                    @size-change="sizeChange"
                    @current-change="pageChange"
                    :current-page="page.pageNumber"
                    :page-sizes="[10, 15, 30, 50]"
                    :page-size="page.pageSize"
                    :background="true"
                    layout="sizes,prev, pager,next,total"
                    :total="page.total">
            </el-pagination>
        </div>
        <form-dialog ref="formDialog" @closeFormDialog="submit" :title="form.title" :show="form.show" :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
        <el-dialog
                title="当前进度"
                :visible.sync="process.show"
                width="30%">
            <span v-html="process.text"></span>
            <span slot="footer" class="dialog-footer">
    <el-button @click="cancel">取 消</el-button>
  </span>
        </el-dialog>
    </div>
</template>
<script>
    import F from '@/fields/fields';
    import search from "@/components/common/search";

    export default {
        name: "groupSending",
        components: {
            search
        },
        data: function () {
            return {
                baseUrl:'/manage/mail/groupSending',
                process:{
                    show:false,
                    title:'当前进度',
                    text:'',
                    index:null,
                    messageId:null
                },
                page: {
                    search: {},
                    sort: {prop: 'create_time', order: 'descending'},
                    total: 1,
                    pageSize: 10,
                    pageNumber: 1,
                },
                tableData: [],
                actionName: '',
                index:null,
                message_id:null,
                form: {
                    action_name: '',
                    show: false,
                    title: '',
                    data: {},
                    fields: [],
                    rules: {},
                }
            }
        },
        beforeDestroy() {
            this.message_id=null;
        },
        computed: {
            searchFields: function () {
                return F.search('groupSending', 'search');
            },
            showFields: function () {
                return F.show('groupSending', 'show');
            }
        },
        methods: {
            condition: function (search) {
                this.page.search = search;
                this.page.pageNumber = 1;
                this.search();
            },
            send:function(message_id,index){
                if (message_id) {
                    if (this.process.messageId){
                        this.$message.warning('当前还有任务!');
                        return;
                    }
                    this.process.index=index;
                    // this.process.show=true;
                    // this.process.text='准备发送';
                    this.process.messageId=message_id;
                    // this.message_id=message_id;
                }
                if (!this.process.messageId) {
                    // this.process.show=false;
                    return;
                }
                this.$axios.put(this.baseUrl+'/send',{message_id:this.process.messageId}).then(data=>{
                    if (data.data.data.status==10){
                        // this.process.show=false;
                        this.process.text='';
                        this.process.messageId=null;
                        this.$message.success('发送完成!');
                    }else{
                        this.process.text=`已经发送 <b>${data.data.data.send}</b>,即将发送人${data.data.data.current}`;
                        this.send();
                    }
                    this.tableData.splice(this.process.index,1,Object.assign({},this.tableData[this.process.index],data.data.data))
                }).catch(()=>{
                    this.$message.error('出错了');
                    // this.process.text='服务器错误!';
                });
            },
            search: function () {
                this.$axios.get(this.baseUrl, {
                    params: this.page,
                }).then(data => {
                    data = data.data;
                    this.page.total = data.data.total;
                    this.page.pageNumber = data.data.pageNumber;
                    this.page.pageSize = data.data.pageSize;
                    this.tableData = data.data.data;
                });
            },
            submit: function (data) {
                // console.log(data);
                if (data.type === 'cancel') {
                    this.form.show = false;
                    this.$refs.formDialog.clearFiles();
                    return;
                }
                switch (this.actionName) {
                    case 'editMessage':
                        data.data.thumbnail=data.data.thumbnail.length? data.data.thumbnail[0].url:"";
                        this.$axios.put(this.baseUrl+'/' + data.data.message_id, data.data).then(userData => {
                            this.form.show = false;
                            this.$refs.formDialog.clearFiles();
                            this.$message.success('保存成功!');
                            this.tableData.splice(this.form.index, 1, userData.data);
                        });
                        break;
                    case 'addMessage':
                        data.data.thumbnail=data.data.thumbnail.length? data.data.thumbnail[0].url:"";
                        this.$axios.post(this.baseUrl, data.data).then((data) => {
                            this.$message.success('添加成功!');
                            this.form.show = false;
                            this.$refs.formDialog.clearFiles();
                            this.tableData.unshift(data.data);
                        });
                        break;
                }
            },
            editMessage: function (data,index) {
                this.actionName = 'editMessage';
                let form = F.get('groupSending', 'edit',data);
                form.index=index;
                this.form = form;
            },
            addMessage:function(){
                this.actionName = 'addMessage';
                // let form = F.get('groupSending', 'add',{thumbnail:[{url:process.env.VUE_APP_MAIL_HOST+'/uploads/20200614/ac11379591348ac3df3513138199b34f.png'}]});
                let form = F.get('groupSending', 'add');
                form.show = true;
                this.form = form;
            },
            cancel:function(){
                this.$confirm('你确认要取消吗？','操作确认').then(()=>{
                    this.message_id='';
                    this.process.show=false;
                });
            },
            sortChange: function (column) {
                if (!column.order) return;
                this.page.sort = {prop: column.prop, order: column.order};
                this.search();
            },
            sizeChange: function (size) {
                this.page.pageSize = size;
                this.search();
            },
            pageChange: function (page) {
                this.page.pageNumber = page;
                this.search();
            },
        }
    }
</script>
<style scoped>

</style>
